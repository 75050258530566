// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-js": () => import("/opt/build/repo/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-contact-page-js": () => import("/opt/build/repo/src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-footer-js": () => import("/opt/build/repo/src/templates/footer.js" /* webpackChunkName: "component---src-templates-footer-js" */),
  "component---src-templates-services-js": () => import("/opt/build/repo/src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-header-js": () => import("/opt/build/repo/src/templates/header.js" /* webpackChunkName: "component---src-templates-header-js" */),
  "component---src-templates-hero-section-js": () => import("/opt/build/repo/src/templates/hero-section.js" /* webpackChunkName: "component---src-templates-hero-section-js" */),
  "component---src-templates-main-content-js": () => import("/opt/build/repo/src/templates/main-content.js" /* webpackChunkName: "component---src-templates-main-content-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

